import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import ServiceTemplate from './ServiceTemplate'
import Layout from '../components/Layout'

const Service = ({ data }) => {
  const {
    markdownRemark: {
      frontmatter: {
        title,
        description,
        video: { publicURL: video }
      }
    }
  } = data

  const templateData = { title, description, video }

  return (
    <Layout title={title} description={description}>
      <ServiceTemplate {...templateData} />
    </Layout>
  )
}

Service.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  })
}

export default Service

export const pageQuery = graphql`
  query ServiceByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        video {
          absolutePath
        }
      }
    }
  }
`
